<template>
  <!--begin::details View-->
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="mx-auto my-auto text-center" v-if="!isLoaded">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div v-if="isLoaded && error">
    <div class="text-gray-700 pt-3 fs-2 text-center">
      Attenzione! Si è verificato un errore. Riprovare più tardi.
    </div>
  </div>
  <div v-if="isLoaded && impiantoDetail && !error">
    <div
      class="row align-items-baseline"
      v-if="
        impiantoDetail.stato_omologazione &&
        (impiantoDetail.puo_modificare_stato ||
          impiantoDetail.puo_annullareappr_modificaliv)
      "
    >
      <div class="">
        <div class="row align-items-baseline">
          <div
            class="col-md-3 m-1"
            v-show="
              impiantoDetail.stato_omologazione == 5 ||
              impiantoDetail.stato_omologazione == 3
            "
          >
            <button
              style="border: 1px solid #9ef99e"
              type="button"
              class="btn btn-light-success"
              name="azioneOmologazione"
              @click="prendiInCarico(impiantoDetail.id_omologazione_impianti)"
            >
              Prendi in carico
            </button>
          </div>
          <div
            class="col-md-3 m-1"
            v-show="impiantoDetail.stato_omologazione == 6"
          >
            <button
              style="border: 1px solid #ffa6a6"
              type="button"
              class="btn btn-light-danger"
              name="azioneOmologazione"
              @click="
                annullaPresaInCarico(impiantoDetail.id_omologazione_impianti)
              "
            >
              Annulla presa in carico
            </button>
          </div>
          <div
            class="col-md-3 m-1"
            v-show="impiantoDetail.stato_omologazione == 6"
          >
            <button
              style="border: 1px solid #ffd600"
              type="button"
              class="btn btn-light-warning"
              name="azioneOmologazione"
              @click="
                inAttesaDiVerifica(impiantoDetail.id_omologazione_impianti)
              "
            >
              In attesa di verifica
            </button>
          </div>
          <div
            class="col-md-3 m-1"
            v-show="impiantoDetail.stato_omologazione == 1"
          >
            <button
              style="border: 1px solid #ffd600"
              type="button"
              class="btn btn-light-warning"
              @click="
                annullaApprovazione(impiantoDetail.id_omologazione_impianti)
              "
            >
              Annulla Approvazione
            </button>
          </div>
          <div
            class="col-md-3 m-1"
            v-show="impiantoDetail.stato_omologazione == 2"
          >
            <button
              style="border: 1px solid #9ef99e"
              type="button"
              class="btn btn-light-success"
              @click="approva(impiantoDetail.id_omologazione_impianti)"
              name="azioneOmologazione"
            >
              Approva omologazione
            </button>
          </div>
          <div
            class="col-md-3 m-1"
            v-show="impiantoDetail.stato_omologazione == 2"
          >
            <button
              style="border: 1px solid #ffa6a6"
              type="button"
              class="btn btn-light-danger"
              @click="rifiuta(impiantoDetail.id_omologazione_impianti)"
              name="azioneOmologazione"
            >
              Rifiuta
            </button>
          </div>
        </div>
      </div>
      <!--    <div class="col-md-4 text-end">
        <router-link
          :to="`/impianti/omologazioni-impianto/${id}`"
          class="btn btn-light text-gray-700 fs-6 text-start"
        >
          <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista omologazioni
          impianto
        </router-link>
      </div> -->
    </div>
    <br />
    <!--begin::Card header-->
    <Form
      class="form"
      @submit="onSubmit"
      :validation-schema="schema"
      @invalid-submit="onInvalidSubmit"
      v-slot="{ values }"
    >
      <div class="alert alert-danger" role="alert" v-show="alertCampiObb">
        Compila i campi obbligatori
      </div>
      <div class="accordion" id="accordionOmologazioneImpianto">
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionOmologazioneImpianto-headingOne"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionOmologazioneImpianto-collapseOne"
                aria-expanded="true"
                aria-controls="accordionOmologazioneImpianto-collapseOne"
              >
                Dati Impianto
              </button>
            </h6>
            <div
              id="accordionOmologazioneImpianto-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionOmologazioneImpianto-headingOne"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="row align-items-baseline">
                    <div class="mb-4 col-md-6">
                      <label class="fw-bold text-gray-600"
                        >Denominazione*</label
                      >
                      <Field
                        name="denominazione"
                        type="text"
                        :value="impiantoDetail.denominazione"
                        class="form-control"
                        disabled
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="denominazione"
                      />
                    </div>
                    <div class="mb-4 col-md-3">
                      <label class="fw-bold text-gray-600">Codice</label>
                      <Field
                        name="cod_impianto"
                        type="text"
                        :value="impiantoDetail.cod_impianto"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row align-items-baseline">
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Tipologia*</label>
                      <Field
                        name="tipo_impianto"
                        type="text"
                        :value="impiantoDetail.tipo_impianto"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Proprietà*</label>
                      <Field
                        name="tipo_proprieta"
                        type="text"
                        :value="impiantoDetail.tipo_proprieta"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row align-items-baseline">
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Provincia*</label>
                      <Field
                        name="provincia"
                        type="text"
                        :value="impiantoDetail.provincia"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Comune*</label>
                      <Field
                        name="comune"
                        type="text"
                        :value="impiantoDetail.comune"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row align-items-baseline">
                    <div class="mb-4 col-md-5">
                      <label class="fw-bold text-gray-600">Indirizzo*</label>
                      <Field
                        name="indirizzo"
                        type="text"
                        :value="impiantoDetail.indirizzo"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <div class="mb-4 col-md-3">
                      <label class="fw-bold text-gray-600">N° Civico</label>
                      <Field
                        name="num_civico"
                        type="text"
                        :value="impiantoDetail.num_civico"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <div class="mb-4 col-md-3">
                      <label class="fw-bold text-gray-600">C.A.P.</label>
                      <Field
                        name="cap"
                        type="text"
                        :value="impiantoDetail.cap"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row align-items-baseline">
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Telefono*</label>
                      <Field
                        name="telefono"
                        type="text"
                        :value="impiantoDetail.telefono"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Fax</label>
                      <Field
                        name="fax"
                        type="text"
                        :value="impiantoDetail.fax"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">E-mail*</label>
                      <Field
                        name="email"
                        type="text"
                        :value="impiantoDetail.email"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row align-items-baseline">
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Sito web</label>
                      <Field
                        name="web"
                        type="text"
                        :value="impiantoDetail.web"
                        class="form-control"
                        disabled
                      />
                    </div>
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Referente*</label>
                      <Field
                        name="referente"
                        type="text"
                        v-model="impiantoDetail.referente"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="referente"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data costruzione impianto</label
                      >
                      <Datepicker
                        v-model="impiantoDetail.data_costruzione"
                        name="data_costruzione"
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        calendarCellClassName="fontData"
                        autoApply
                        :maxDate="new Date()"
                        :disabled="true"
                      />
                      <Field
                        name="data_costruzione"
                        v-model="impiantoDetail.data_costruzione"
                        type="hidden"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_Scadenza_Permesso_Soggiorno"
                      />
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <form autocomplete="off">
          <div class="card shadow mb-7">
            <div class="accordion-item">
              <h6
                class="accordion-header"
                id="accordionOmologazioneImpianto-headingFour"
              >
                <button
                  class="accordion-button collapsed fw-bolder m-0 p-6"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionOmologazioneImpianto-collapseFour"
                  aria-expanded="true"
                  aria-controls="accordionOmologazioneImpianto-collapseFour"
                >
                  Dati omologazione
                </button>
              </h6>
              <div
                id="accordionOmologazioneImpianto-collapseFour"
                class="accordion-collapse collapse show"
                aria-labelledby="accordionOmologazioneImpianto-headingFour"
              >
                <div class="accordion-body p-0 m-0">
                  <!--begin::Card body-->
                  <div class="card-body p-6">
                    <div class="ps-5 pe-5">
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-6">
                          <label class="fw-bold text-gray-600"
                            >Richiedente*</label
                          >
                          <!-- :value="impiantoDetail.richiedente" -->
                          <Field
                            name="richiedente"
                            type="text"
                            :value="
                              societa_nome
                                ? societa_nome
                                : impiantoDetail.richiedente
                            "
                            class="form-control"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="richiedente"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label class="fw-bold text-gray-600">Stagione*</label>
                          <SelectInput
                            :options="stagioni"
                            name="anno"
                            placeholder="-"
                            :value="defaultStagione"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Disciplina*</label
                          >
                          <SelectInput
                            :options="discipline_impianto"
                            placeholder="-"
                            name="id_disciplina"
                            :value="disciplinaSelected"
                            :disabled="disciplinaSelected && id_omo"
                            @changeSelect="changeDisciplinaOmologazione($event)"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-3">
                          <label class="fw-bold text-gray-600"
                            >Tipologia*
                          </label>
                          <SelectInput
                            :options="omologazioni_tipologia"
                            placeholder="-"
                            name="id_tipologia_omologazione"
                            :value="selTipologiaOmologazione"
                            @changeSelect="changeTipologiaOmologazione($event)"
                            :disabled="!disciplinaSelected"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label class="fw-bold text-gray-600"
                            >Data inizio*</label
                          >
                          <Datepicker
                            v-model="impiantoDetail.data_inizio"
                            @update:modelValue="
                              $event
                                ? (impiantoDetail.data_inizio =
                                    fromUTCtoISODate($event))
                                : ''
                            "
                            placeholder=""
                            :format="format"
                            :monthChangeOnScroll="false"
                            :enableTimePicker="false"
                            inputClassName="h-28"
                            autoApply
                            :disabled="
                              !disciplinaSelected ||
                              selTipologiaOmologazione != 2
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="data_inizio"
                          />
                          <Field
                            name="data_inizio"
                            type="hidden"
                            class="form-control"
                            v-model="impiantoDetail.data_inizio"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label class="fw-bold text-gray-600"
                            >Data fine*</label
                          >
                          <!-- -->
                          <Datepicker
                            v-model="impiantoDetail.data_fine"
                            @update:modelValue="
                              $event
                                ? (impiantoDetail.data_fine =
                                    fromUTCtoISODate($event))
                                : ''
                            "
                            placeholder=""
                            :format="format"
                            :monthChangeOnScroll="false"
                            :enableTimePicker="false"
                            inputClassName="h-28"
                            autoApply
                            :disabled="
                              !disciplinaSelected ||
                              selTipologiaOmologazione != 2
                            "
                          />
                          <Field
                            name="data_fine"
                            type="hidden"
                            class="form-control"
                            v-model="impiantoDetail.data_fine"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="data_fine"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label class="fw-bold text-gray-600"
                            >Data costruzione impianto</label
                          >
                          <!-- :disabled="
                              !disciplinaSelected ||
                              selTipologiaOmologazione != 2
                            " -->
                          <Datepicker
                            v-model="impiantoDetail.data_costruzione_impianto"
                            @update:modelValue="
                              $event
                                ? (impiantoDetail.data_costruzione_impianto =
                                    fromUTCtoISODate($event))
                                : ''
                            "
                            placeholder=""
                            :format="format"
                            :monthChangeOnScroll="false"
                            :enableTimePicker="false"
                            inputClassName="h-28"
                            autoApply
                          />
                          <Field
                            name="data_costruzione_impianto"
                            type="hidden"
                            class="form-control"
                            v-model="impiantoDetail.data_costruzione_impianto"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="data_costruzione_impianto"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div
                          class="mb-4 col-md-4"
                          v-if="impiantoDetail.puo_gestire_campo_livello"
                        >
                          <label class="fw-bold text-gray-600">
                            Livello*
                          </label>
                          <SelectInput
                            :options="omologazioni_livelli_impianti"
                            placeholder="-"
                            name="id_livello_omologazione"
                            :value="
                              impiantoDetail.id_livello_omologazione == 0
                                ? null
                                : impiantoDetail.id_livello_omologazione
                            "
                            @changeSelect="changeIdLivelloOmologazione($event)"
                            :disabled="
                              !disciplinaSelected ||
                              impiantoDetail.stato_omologazione != 2
                            "
                          />
                          <Field
                            type="hidden"
                            v-model="impiantoDetail.stato_omologazione"
                            name="stato_omologazione"
                          />
                        </div>
                        <div class="mb-4 col-md-4">
                          <label class="fw-bold text-gray-600"
                            >Ente proprietario*
                          </label>
                          <Field
                            name="ente_proprietario"
                            type="text"
                            :value="
                              enteProprietario
                                ? enteProprietario
                                : impiantoDetail.ente_proprietario
                            "
                            @input="setEnteProprietario($event)"
                            class="form-control"
                            :disabled="!disciplinaSelected"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="ente_proprietario"
                          />
                        </div>

                        <div class="mb-4 col-md-4">
                          <label class="fw-bold text-gray-600"
                            >Ente gestore</label
                          >
                          <div class="dp__input_wrap">
                            <Field
                              placeholder="Ente gestore"
                              name="ente_gestore"
                              type="text"
                              class="form-control"
                              autocomplete="off"
                              @input="getSocieta($event.target.value)"
                              v-model="impiantoDetail.ente_gestore"
                              :disabled="!disciplinaSelected"
                            />
                            <Field
                              name="id_ente_gestore"
                              type="hidden"
                              class="form-control"
                              v-model="impiantoDetail.id_ente_gestore"
                            />
                            <svg
                              @click="setSocieta(null)"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              class="dp__icon dp__clear_icon dp__input_icons"
                            >
                              <path
                                d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                              ></path>
                              <path
                                d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                              ></path>
                            </svg>
                          </div>
                          <ListSocieta
                            :focusOnInput="true"
                            :list="societa"
                            @hideList="resetSocieta"
                            @selectedSocieta="
                              setSocieta($event);
                              resetSocieta();
                            "
                          ></ListSocieta>
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-5">
                          <label class="fw-bold text-gray-600"
                            >Disponibilità da parte dell'ente
                            richiedente*</label
                          >
                          <SelectInput
                            :options="omologazioni_disponibilita_ente_impianti"
                            placeholder="-"
                            name="id_disponibilita_ente"
                            :value="selDisponibilitaEnte"
                            @changeSelect="changeDisponibilitaEnte($event)"
                            :disabled="!disciplinaSelected"
                          />
                        </div>
                        <div class="mb-4 col-md-7">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Altro*</label
                          >
                          <Field
                            name="disponibilita_ente_altro"
                            type="text"
                            v-model="impiantoDetail.disponibilita_ente_altro"
                            class="form-control"
                            :disabled="
                              !disciplinaSelected || selDisponibilitaEnte != 5
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="disponibilita_ente_altro"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-4">
                          <label class="fw-bold text-gray-600"
                            >Durata disponibilità*</label
                          >
                          <SelectInput
                            :options="
                              omologazioni_durata_disponibilita_impianti
                            "
                            placeholder="-"
                            name="id_durata_disponibilita"
                            :value="selDurataDisponibilitaEnte"
                            @changeSelect="
                              changeDurataDisponibilitaEnte($event)
                            "
                            :disabled="!disciplinaSelected"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Mesi</label
                          >
                          <Field
                            name="mesi_disponibilita"
                            type="number"
                            min="0"
                            v-model="impiantoDetail.mesi_disponibilita"
                            class="form-control"
                            :disabled="
                              !disciplinaSelected ||
                              selDurataDisponibilitaEnte != 4
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="mesi_disponibilita"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Anni</label
                          >
                          <Field
                            name="anni_disponibilita"
                            type="number"
                            min="0"
                            v-model="impiantoDetail.anni_disponibilita"
                            class="form-control"
                            :disabled="
                              !disciplinaSelected ||
                              selDurataDisponibilitaEnte != 4
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="anni_disponibilita"
                          />
                        </div>
                        <div class="mb-4 col-md-4">
                          <label class="fw-bold text-gray-600"
                            >Tipologie di gara*
                          </label>
                          <Multiselect
                            v-model="selectedTipologieGare"
                            :options="omologazioni_tipo_gara"
                            mode="tags"
                            :close-on-select="false"
                            :searchable="true"
                            :create-option="false"
                            @input="inputElement"
                            :disabled="
                              !disciplinaSelected ||
                              !impiantoDetail.puo_modificare_dati
                            "
                          />
                          <Field
                            name="id_tipologia_gara"
                            type="hidden"
                            v-model="id_tipologia_gara"
                            class="form-control"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="id_tipologia_gara"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="card shadow mb-7">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionOmologazioneImpianto-headingFour"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionOmologazioneImpianto-collapseFive"
              aria-expanded="true"
              aria-controls="accordionOmologazioneImpianto-collapseFive"
            >
              Spazi di supporto atleti
            </button>
          </h6>
          <div
            id="accordionOmologazioneImpianto-collapseFive"
            class="accordion-collapse collapse show"
            aria-labelledby="accordionOmologazioneImpianto-headingFive"
          >
            <div class="accordion-body p-0 m-0">
              <!--begin::Card body-->
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row bg-light pt-4 align-items-baseline">
                    <div class="mb-4 col fw-bold pt-7" style="max-width: 35px">
                      1)
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">N. spogliatoi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_1"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_1"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_1"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >dimensioni (mq)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_dimensioni_1"
                        v-model="impiantoDetail.spogliatoi_atleti_dimensioni_1"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_dimensioni_1"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >n. docce (in ognuno)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_docce_1"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_docce_1
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_docce_1"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. lavabi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_lavabi_1"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_lavabi_1
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_lavabi_1"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. WC</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_wc_1"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_wc_1"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_wc_1"
                      />
                    </div>
                  </div>
                  <div class="row pt-4 align-items-baseline">
                    <div class="mb-4 col fw-bold pt-7" style="max-width: 35px">
                      2)
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">N. spogliatoi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_2"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_2"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_2"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >dimensioni (mq)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_dimensioni_2"
                        v-model="impiantoDetail.spogliatoi_atleti_dimensioni_2"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_dimensioni_2"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >n. docce (in ognuno)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_docce_2"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_docce_2
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_docce_2"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. lavabi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_lavabi_2"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_lavabi_2
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_lavabi_2"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. WC</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_wc_2"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_wc_2"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_wc_2"
                      />
                    </div>
                  </div>
                  <div class="row bg-light pt-4 align-items-baseline">
                    <div class="mb-4 col fw-bold pt-7" style="max-width: 35px">
                      3)
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">N. spogliatoi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_3"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_3"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_3"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >dimensioni (mq)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_dimensioni_3"
                        v-model="impiantoDetail.spogliatoi_atleti_dimensioni_3"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_dimensioni_3"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >n. docce (in ognuno)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_docce_3"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_docce_3
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_docce_3"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. lavabi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_lavabi_3"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_lavabi_3
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_lavabi_3"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. WC</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_wc_3"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_wc_3"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_wc_3"
                      />
                    </div>
                  </div>
                  <div class="row pt-4 align-items-baseline">
                    <div class="mb-4 col fw-bold pt-7" style="max-width: 35px">
                      4)
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">N. spogliatoi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_4"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_4"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_4"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >dimensioni (mq)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_dimensioni_4"
                        v-model="impiantoDetail.spogliatoi_atleti_dimensioni_4"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_dimensioni_4"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >n. docce (in ognuno)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_docce_4"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_docce_4
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_docce_4"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. lavabi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_lavabi_4"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_lavabi_4
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_lavabi_4"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. WC</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_wc_4"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_wc_4"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_wc_4"
                      />
                    </div>
                  </div>
                  <div class="row bg-light pt-4 align-items-baseline">
                    <div class="mb-4 col fw-bold pt-7" style="max-width: 35px">
                      5)
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">N. spogliatoi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_5"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_5"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_5"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >dimensioni (mq)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_dimensioni_5"
                        v-model="impiantoDetail.spogliatoi_atleti_dimensioni_5"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_dimensioni_5"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >n. docce (in ognuno)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_docce_5"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_docce_5
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_docce_5"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. lavabi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_lavabi_5"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_lavabi_5
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_lavabi_5"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. WC</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_wc_5"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_wc_5"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_wc_5"
                      />
                    </div>
                  </div>
                  <div class="row pt-4 align-items-baseline">
                    <div class="mb-4 col fw-bold pt-7" style="max-width: 35px">
                      6)
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">N. spogliatoi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_6"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_6"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_6"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >dimensioni (mq)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_dimensioni_6"
                        v-model="impiantoDetail.spogliatoi_atleti_dimensioni_6"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_dimensioni_6"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >n. docce (in ognuno)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_docce_6"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_docce_6
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_docce_6"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. lavabi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_lavabi_6"
                        v-model="
                          impiantoDetail.spogliatoi_atleti_numero_lavabi_6
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_lavabi_6"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. WC</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_atleti_numero_wc_6"
                        v-model="impiantoDetail.spogliatoi_atleti_numero_wc_6"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_atleti_numero_wc_6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Card body-->
          </div>
        </div>
      </div>

      <div class="card shadow mb-7">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionOmologazioneImpianto-headingFour"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionOmologazioneImpianto-collapseEight"
              aria-expanded="true"
              aria-controls="accordionOmologazioneImpianto-collapseEight"
            >
              Spazi di supporto giudici
            </button>
          </h6>
          <div
            id="accordionOmologazioneImpianto-collapseEight"
            class="accordion-collapse collapse show"
            aria-labelledby="accordionOmologazioneImpianto-headingFive"
          >
            <div class="accordion-body p-0 m-0">
              <!--begin::Card body-->
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row bg-light pt-4 align-items-baseline">
                    <div class="mb-4 col fw-bold pt-7" style="max-width: 35px">
                      1)
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">N. spogliatoi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_1"
                        v-model="impiantoDetail.spogliatoi_giudici_numero_1"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_1"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >dimensioni (mq)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_dimensioni_1"
                        v-model="impiantoDetail.spogliatoi_giudici_dimensioni_1"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_dimensioni_1"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >n. docce (in ognuno)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_docce_1"
                        v-model="
                          impiantoDetail.spogliatoi_giudici_numero_docce_1
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_docce_1"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. lavabi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_lavabi_1"
                        v-model="
                          impiantoDetail.spogliatoi_giudici_numero_lavabi_1
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_lavabi_1"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. WC</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_wc_1"
                        v-model="impiantoDetail.spogliatoi_giudici_numero_wc_1"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_wc_1"
                      />
                    </div>
                  </div>
                  <div class="row pt-4 align-items-baseline">
                    <div class="mb-4 col fw-bold pt-7" style="max-width: 35px">
                      2)
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">N. spogliatoi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_2"
                        v-model="impiantoDetail.spogliatoi_giudici_numero_2"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_2"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >dimensioni (mq)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_dimensioni_2"
                        v-model="impiantoDetail.spogliatoi_giudici_dimensioni_2"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_dimensioni_2"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >n. docce (in ognuno)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_docce_2"
                        v-model="
                          impiantoDetail.spogliatoi_giudici_numero_docce_2
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_docce_2"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. lavabi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_lavabi_2"
                        v-model="
                          impiantoDetail.spogliatoi_giudici_numero_lavabi_2
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_lavabi_2"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. WC</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_wc_2"
                        v-model="impiantoDetail.spogliatoi_giudici_numero_wc_2"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_wc_2"
                      />
                    </div>
                  </div>
                  <div class="row bg-light pt-4 align-items-baseline">
                    <div class="mb-4 col fw-bold pt-7" style="max-width: 35px">
                      3)
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">N. spogliatoi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_3"
                        v-model="impiantoDetail.spogliatoi_giudici_numero_3"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_3"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >dimensioni (mq)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_dimensioni_3"
                        v-model="impiantoDetail.spogliatoi_giudici_dimensioni_3"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_dimensioni_3"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600"
                        >n. docce (in ognuno)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_docce_3"
                        v-model="
                          impiantoDetail.spogliatoi_giudici_numero_docce_3
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_docce_3"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. lavabi</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_lavabi_3"
                        v-model="
                          impiantoDetail.spogliatoi_giudici_numero_lavabi_3
                        "
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_lavabi_3"
                      />
                    </div>
                    <div class="mb-4 col">
                      <label class="fw-bold text-gray-600">n. WC</label>
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="spogliatoi_giudici_numero_wc_3"
                        v-model="impiantoDetail.spogliatoi_giudici_numero_wc_3"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="spogliatoi_giudici_numero_wc_3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Card body-->
          </div>
        </div>
      </div>

      <div class="card shadow mb-7">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionOmologazioneImpianto-headingFour"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionOmologazioneImpianto-collapseSix"
              aria-expanded="true"
              aria-controls="accordionOmologazioneImpianto-collapseSix"
            >
              Spazi aggiuntivi
            </button>
          </h6>
          <div
            id="accordionOmologazioneImpianto-collapseSix"
            class="accordion-collapse collapse show"
            aria-labelledby="accordionOmologazioneImpianto-headingSix"
          >
            <div class="accordion-body p-0 m-0">
              <!--begin::Card body-->
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row align-items-baseline">
                    <div class="mb-4 col-md-3">
                      <label class="fw-bold text-gray-600"
                        >N. Pronto Soccorso / Antidoping</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="pronto_soccorso_numero"
                        v-model="impiantoDetail.pronto_soccorso_numero"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="pronto_soccorso_numero"
                      />
                    </div>
                    <div class="mb-4 col-md-3">
                      <label class="fw-bold text-gray-600"
                        >dimensioni (mq)</label
                      >
                      <Field
                        type="number"
                        min="0"
                        class="form-control"
                        name="pronto_soccorso_dimensioni"
                        v-model="impiantoDetail.pronto_soccorso_dimensioni"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="pronto_soccorso_dimensioni"
                      />
                    </div>
                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600"
                        >Spogliatoio wheelchair</label
                      >
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="spogliatoi_accessibilita"
                        placeholder="-"
                        :value="impiantoDetail.spogliatoi_accessibilita"
                        :disabled="!disciplinaSelected"
                      />
                    </div>
                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600"
                        >Spazi per riposo</label
                      >
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="spazi_riposo_giocatori"
                        placeholder="-"
                        :value="spazi_riposo_giocatori"
                        :disabled="!disciplinaSelected"
                      />
                    </div>
                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600"
                        >Sala riunioni/Sede soc.</label
                      >
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="sala_riunioni"
                        placeholder="-"
                        :value="sala_riunioni"
                        :disabled="!disciplinaSelected"
                      />
                    </div>
                  </div>
                  <div class="row align-items-baseline">
                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600"
                        >Ristoro automatico</label
                      >
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="ristoro"
                        placeholder="-"
                        :value="ristoro"
                        :disabled="!disciplinaSelected"
                      />
                    </div>
                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600"
                        >Ristoro con addetto</label
                      >
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="ristoro_con_addetto"
                        placeholder="-"
                        :value="ristoro_con_addetto"
                        :disabled="!disciplinaSelected"
                      />
                    </div>
                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600"
                        >Locale ristoro</label
                      >
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="locale_ristoro"
                        placeholder="-"
                        :value="locale_ristoro"
                        :disabled="!disciplinaSelected"
                      />
                    </div>
                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600">Ristorante</label>
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="ristorante"
                        placeholder="-"
                        :value="ristorante"
                        :disabled="!disciplinaSelected"
                      />
                    </div>
                    <!--  <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600"
                        >Servizi ristoro automatici</label
                      >
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="servizi_ristoro_automatici"
                        placeholder="-"
                        :value="servizi_ristoro_automatici"
                        :disabled="!disciplinaSelected"
                      />
                    </div> -->
                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600"
                        >Attività commerciali</label
                      >
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="attivita_commerciali"
                        placeholder="-"
                        :value="attivita_commerciali"
                        :disabled="!disciplinaSelected"
                      />
                    </div>

                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600">Palestra</label>
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="palestra_attivita_fisica"
                        placeholder="-"
                        :value="palestra_attivita_fisica"
                        :disabled="!disciplinaSelected"
                      />
                    </div>
                  </div>
                  <div class="row align-items-baseline">
                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600">Spazio verde</label>
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="spazio_verde"
                        placeholder="-"
                        :value="spazio_verde"
                        :disabled="!disciplinaSelected"
                      />
                    </div>
                    <div class="mb-4 col-md-2">
                      <label class="fw-bold text-gray-600">Parcheggi</label>
                      <SelectInput2
                        :options="optionsTrueFalse"
                        name="parcheggi"
                        placeholder="-"
                        :value="parcheggi"
                        :disabled="!disciplinaSelected"
                      />
                    </div>
                    <div class="mb-4 col-md-8">
                      <label class="fw-bold text-gray-600">Altro</label>
                      <Field
                        type="text"
                        class="form-control"
                        name="altro"
                        v-model="impiantoDetail.altro"
                        :disabled="!disciplinaSelected"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="altro"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end::Card body-->
        </div>
      </div>

      <div class="card shadow mb-7" v-if="impiantoDetail.campi">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionOmologazioneImpianto-headingFour"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionOmologazioneImpianto-collapseSeven"
              aria-expanded="true"
              aria-controls="accordionOmologazioneImpianto-collapseSeven"
            >
              Campi da gioco
            </button>
          </h6>
          <div
            id="accordionOmologazioneImpianto-collapseSeven"
            class="accordion-collapse collapse show"
            aria-labelledby="accordionOmologazioneImpianto-headingSeven"
          >
            <div class="accordion-body p-0 m-0">
              <div class="card-body p-6">
                <FieldArray name="campi">
                  <div
                    v-for="(campo, index) in impiantoDetail.campi"
                    :key="index"
                    class="card shadow-sm mb-4"
                  >
                    <div class="ps-5 pe-5 p-5">
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-4">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Identificativo campo*</label
                          >
                          <Field
                            :name="`campi[${index}].id_campo`"
                            type="text"
                            :value="campo.id_campo"
                            class="form-control"
                            disabled
                          />
                          <Field
                            :name="`campi[${index}].id_omologazione_impianti_campi`"
                            type="hidden"
                            :value="campo.id_omologazione_impianti_campi"
                            class="form-control"
                            disabled
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].id_campo`"
                          />
                        </div>
                        <div class="mb-4 col-md-4">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Descrizione*</label
                          >
                          <Field
                            :name="`campi[${index}].nome_campo`"
                            type="text"
                            v-model="campo.nome_campo"
                            class="form-control"
                            disabled
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].nome_campo`"
                          />
                        </div>
                        <div class="mb-4 col-md-4">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Disciplina*</label
                          >
                          <Field
                            :name="`campi[${index}].disciplina`"
                            type="text"
                            :value="campo.disciplina"
                            class="form-control"
                            disabled
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].disciplina`"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Lunghezza (m)*</label
                          >
                          <Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].lunghezza`"
                            v-model="campo.lunghezza"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].lunghezza`"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Larghezza (m)*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].larghezza`"
                            v-model="campo.larghezza"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].larghezza`"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Out Laterali 1 (m)*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].out_laterali_sinistro`"
                            v-model="campo.out_laterali_sinistro"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].out_laterali_sinistro`"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Out Laterali 2 (m)*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].out_laterali_destro`"
                            v-model="campo.out_laterali_destro"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].out_laterali_destro`"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Out Fondo 1 (m)*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].out_fondo_sinistro`"
                            v-model="campo.out_fondo_sinistro"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].out_fondo_sinistro`"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Out Fondo 2 (m)*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].out_fondo_destro`"
                            v-model="campo.out_fondo_destro"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].out_fondo_destro`"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-4">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Distanza linea di battuta dalla rete 1 (m)
                            {{
                              campo.id_disciplina == 172 ||
                              campo.id_disciplina == 4332
                                ? "*"
                                : ""
                            }}</label
                          >
                          <Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].distanza_linea_battuta_1`"
                            v-model="campo.distanza_linea_battuta_1"
                            :disabled="
                              campo.id_disciplina == 171 &&
                              campo.id_disciplina == 173
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].distanza_linea_battuta_1`"
                          />
                        </div>
                        <div class="mb-4 col-md-4">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Distanza linea di battuta dalla rete 2 (m){{
                              campo.id_disciplina == 172 ||
                              campo.id_disciplina == 4332
                                ? "*"
                                : ""
                            }}</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].distanza_linea_battuta_2`"
                            v-model="campo.distanza_linea_battuta_2"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].distanza_linea_battuta_2`"
                          />
                        </div>
                        <div class="mb-4 col-md-4">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Altezza libera minima su tutta la superficie di
                            gioco superiore a 9m</label
                          >
                          <SelectInput2
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].altezza_libera_minima_tennis`"
                            placeholder="-"
                            :value="campo.altezza_libera_minima_tennis"
                            :disabled="campo.id_disciplina == 172"
                          />
                        </div>
                      </div>
                      <div
                        class="row align-items-baseline"
                        v-if="campo.id_disciplina == 172"
                      >
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Altezza libera minima su tutta la superficie di
                            gioco superiore a 6m{{
                              campo.id_disciplina == 172 ? "*" : ""
                            }}</label
                          >
                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].altezza_libera_minima_padel`"
                            placeholder="-"
                            :value="campo.altezza_libera_minima_padel"
                            :disabled="campo.id_disciplina != 172"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Distanza laterale della riga centrale di battuta 1
                            (m){{
                              campo.id_disciplina == 172 ? "*" : ""
                            }}</label
                          >
                          <Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].distanza_laterale_battuta_1`"
                            v-model="campo.distanza_laterale_battuta_1"
                            :disabled="campo.id_disciplina != 172"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].distanza_laterale_battuta_1`"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Distanza laterale della riga centrale di battuta 2
                            (m){{
                              campo.id_disciplina == 172 ? "*" : ""
                            }}</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].distanza_laterale_battuta_2`"
                            v-model="campo.distanza_laterale_battuta_2"
                            :disabled="campo.id_disciplina != 172"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].distanza_laterale_battuta_2`"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Tipologia vetro campo{{
                              campo.id_disciplina == 172 ? "*" : ""
                            }}:</label
                          >
                          <SelectInput
                            :options="omologazioni_tipologia_vetro_campi"
                            :name="`campi[${index}].id_tipologia_vetro`"
                            placeholder="-"
                            :value="campo.id_tipologia_vetro"
                            :disabled="campo.id_disciplina != 172"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Spessore vetro
                            {{
                              campo.id_disciplina == 172 ? "(mm)*" : ""
                            }}</label
                          >
                          <Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].spessore_vetro`"
                            v-model="campo.spessore_vetro"
                            :disabled="campo.id_disciplina != 172"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].spessore_vetro`"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline pt-2 mb-4">
                        <div class="col-md-4">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Numero posti a sedere giocatori</label
                          >
                          <Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].numero_posti`"
                            v-model="campo.numero_posti"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].numero_posti`"
                          />
                        </div>
                        <div class="col-md-8">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Tipologia campo aperto/coperto*</label
                          >
                          <SelectInput
                            :options="omologazioni_tipo_impianti_campo"
                            placeholder="-"
                            :name="`campi[${index}].id_tipo_campo_omologazione`"
                            :value="campo.id_tipo_campo_omologazione"
                            @changeSelect="
                              changeTipologiaCoperturaCampo(index, $event)
                            "
                          />
                        </div>
                      </div>
                      <div v-if="campo.id_tipo_campo_omologazione == 2">
                        <div class="row align-items-baseline">
                          <div class="mb-4 col-md-4">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="descrizione"
                              >Copertura</label
                            >
                            <SelectInput
                              :options="omologazioni_tipo_copertura_campi"
                              :name="`campi[${index}].tipo_copertura`"
                              placeholder="-"
                              :value="campo.tipo_copertura"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                          </div>
                          <div class="mb-4 col-md-5">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="descrizione"
                              >Tipologia*</label
                            >
                            <SelectInput
                              :options="omologazioni_campi_coperti"
                              :name="`campi[${index}].tipologie_campi_coperti`"
                              placeholder="-"
                              :value="campo.tipologie_campi_coperti"
                              @changeSelect="
                                changeTipologiaCoperturaCampoCoperto(
                                  index,
                                  $event
                                )
                              "
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                          </div>
                          <div class="mb-4 col-md-3">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="descrizione"
                              >Presenza copertura con aperture scorrevoli
                              laterali</label
                            >
                            <SelectInput
                              :options="optionsTrueFalse"
                              :name="`campi[${index}].aperture_scorrevoli_laterali`"
                              placeholder="-"
                              :value="campo.aperture_scorrevoli_laterali"
                              :disabled="campo.id_tipo_campo_omologazione < 2"
                            />
                          </div>
                        </div>
                        <div class="row align-items-baseline">
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="descrizione"
                              >Impianto climatizzazione campi*</label
                            >
                            <SelectInput
                              :options="optionsTrueFalse"
                              :name="`campi[${index}].presenza_climatizzazione`"
                              placeholder="-"
                              :value="campo.presenza_climatizzazione"
                              @changeSelect="setClimatizzazione(index, $event)"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                          </div>
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="disciplina"
                              >Altezza linea laterale 1 (m)*</label
                            >

                            <Field
                              type="number"
                              min="0"
                              class="form-control"
                              :name="`campi[${index}].altezza_laterale_1`"
                              v-model="campo.altezza_laterale_1"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].altezza_laterale_1`"
                            />
                          </div>
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="disciplina"
                              >Altezza linea laterale 2 (m)*</label
                            ><Field
                              type="number"
                              min="0"
                              class="form-control"
                              :name="`campi[${index}].altezza_laterale_2`"
                              v-model="campo.altezza_laterale_2"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].altezza_laterale_2`"
                            />
                          </div>
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="disciplina"
                              >Altezza linea di fondo 1 (m)*</label
                            ><Field
                              type="number"
                              min="0"
                              class="form-control"
                              :name="`campi[${index}].altezza_fondo_1`"
                              v-model="campo.altezza_fondo_1"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].altezza_fondo_1`"
                            />
                          </div>
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="disciplina"
                              >Altezza linea di fondo 2 (m)*</label
                            ><Field
                              type="number"
                              min="0"
                              class="form-control"
                              :name="`campi[${index}].altezza_fondo_2`"
                              v-model="campo.altezza_fondo_2"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].altezza_fondo_2`"
                            />
                          </div>
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="disciplina"
                              >Altezza Massima (m)*</label
                            ><Field
                              type="number"
                              min="0"
                              class="form-control"
                              :name="`campi[${index}].altezza_massima`"
                              v-model="campo.altezza_massima"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].altezza_massima`"
                            />
                          </div>
                        </div>
                        <div class="row align-items-baseline">
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="disciplina"
                              >Altezza sul centro della rete{{
                                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
                              }}</label
                            >
                            <Field
                              type="number"
                              min="0"
                              class="form-control"
                              :name="`campi[${index}].altezza_centro_rete`"
                              v-model="campo.altezza_centro_rete"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].altezza_centro_rete`"
                            />
                          </div>
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="disciplina"
                              >Altezza sulle linee laterali{{
                                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
                              }}</label
                            >
                            <Field
                              type="number"
                              min="0"
                              class="form-control"
                              :name="`campi[${index}].altezza_linee_laterali`"
                              v-model="campo.altezza_linee_laterali"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].altezza_linee_laterali`"
                            />
                          </div>
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="disciplina"
                              >Altezza a 4,50 mt dalla linea di fondo{{
                                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
                              }}</label
                            ><Field
                              type="number"
                              min="0"
                              class="form-control"
                              :name="`campi[${index}].altezza_linea_fondo`"
                              v-model="campo.altezza_linea_fondo"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].altezza_linea_fondo`"
                            />
                          </div>
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="disciplina"
                              >Altezza ai 4 angoli del campo{{
                                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
                              }}</label
                            ><Field
                              type="number"
                              min="0"
                              class="form-control"
                              :name="`campi[${index}].altezza_angoli`"
                              v-model="campo.altezza_angoli"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].altezza_angoli`"
                            />
                          </div>
                        </div>
                        <div class="row align-items-baseline">
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="descrizione"
                              >Periodo copertura</label
                            >
                            <SelectInput
                              :options="
                                omologazioni_tipologia_copertura_impianti
                              "
                              :name="`campi[${index}].tipologia_copertura`"
                              placeholder="-"
                              :value="campo.tipologia_copertura"
                              @changeSelect="
                                changePeriodoCoperturaCampo(index, $event)
                              "
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                          </div>
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="descrizione"
                              >Data inizio copertura</label
                            >
                            <Datepicker
                              v-model="campo.data_inizio_copertura"
                              @update:modelValue="
                                $event
                                  ? (campo.data_inizio_copertura =
                                      fromUTCtoISODate($event))
                                  : ''
                              "
                              placeholder=""
                              :format="format"
                              :monthChangeOnScroll="false"
                              :enableTimePicker="false"
                              inputClassName="h-28"
                              autoApply
                              :disabled="campo.tipologia_copertura != 1"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].data_inizio_copertura`"
                            />
                            <Field
                              :name="`campi[${index}].data_inizio_copertura`"
                              type="hidden"
                              class="form-control"
                              v-model="campo.data_inizio_copertura"
                            />
                          </div>
                          <div class="mb-4 col-md-2">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="descrizione"
                              >Data fine copertura</label
                            >
                            <Datepicker
                              v-model="campo.data_fine_copertura"
                              @update:modelValue="
                                $event
                                  ? (campo.data_fine_copertura =
                                      fromUTCtoISODate($event))
                                  : ''
                              "
                              placeholder=""
                              :format="format"
                              :monthChangeOnScroll="false"
                              :enableTimePicker="false"
                              inputClassName="h-28"
                              autoApply
                              :disabled="campo.tipologia_copertura != 1"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].data_fine_copertura`"
                            />
                            <Field
                              :name="`campi[${index}].data_fine_copertura`"
                              type="hidden"
                              class="form-control"
                              v-model="campo.data_fine_copertura"
                            />
                          </div>
                          <div class="mb-4 col-md-6">
                            <label
                              class="fw-bold text-gray-600"
                              label-for="descrizione"
                              >Descrizione copertura{{
                                campo.id_tipo_campo_omologazione == 2 ? "*" : ""
                              }}</label
                            >
                            <Field
                              type="text"
                              class="form-control"
                              :name="`campi[${index}].descrizione_copertura`"
                              v-model="campo.descrizione_copertura"
                              :disabled="campo.id_tipo_campo_omologazione != 2"
                            />
                            <ErrorMessage
                              class="help-message"
                              style="color: red"
                              :name="`campi[${index}].descrizione_copertura`"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Impianto wifi raggiungibile dai campi*</label
                          >
                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].presenza_wifi`"
                            placeholder="-"
                            :value="campo.presenza_wifi"
                            @changeSelect="setWiFi(index, $event)"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Impianto illuminazione emergenza*</label
                          >
                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].presenza_illuminazione_emergenza`"
                            placeholder="-"
                            :value="campo.presenza_illuminazione_emergenza"
                            @changeSelect="setIlluminazioneEmerg(index, $event)"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Impianto antincendio*</label
                          >
                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].presenza_impianto_antincendio`"
                            placeholder="-"
                            :value="campo.presenza_impianto_antincendio"
                            @changeSelect="setAntincendio(index, $event)"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Impianto di diffusione sonora*</label
                          >
                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].presenza_impianto_sonoro`"
                            placeholder="-"
                            :value="campo.presenza_impianto_sonoro"
                            @changeSelect="setImpSonoro(index, $event)"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-6">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Pavimentazione campo*</label
                          >
                          <SelectInput
                            :options="impianti_campi_tipo_pavimentazione"
                            :name="`campi[${index}].id_tipo_pavimentazione`"
                            placeholder="-"
                            :value="campo.id_tipo_pavimentazione"
                            @changeSelect="setTipoPavimentazione(index, $event)"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Campo accessibile ai giocatori wheelchair*</label
                          >

                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].accessibile_wheelchair`"
                            placeholder="-"
                            :value="campo.accessibile_wheelchair"
                            @changeSelect="
                              setAccessibileWeelchair(index, $event)
                            "
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Sedia dell'arbitro*</label
                          >

                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].sedia_arbitro`"
                            placeholder="-"
                            :value="campo.sedia_arbitro"
                            @changeSelect="setSediaArbitro(index, $event)"
                            :disabled="campo.id_disciplina == 172"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Impianto di illuminazione artificiale fissa del
                            campo di gioco*</label
                          >
                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].illuminazione_artificiale_fissa`"
                            placeholder="-"
                            :value="campo.illuminazione_artificiale_fissa"
                            @changeSelect="
                              changeIlluminazioneArtificialeFissaCampo(
                                index,
                                $event
                              )
                            "
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Apparecchi illuminanti n.*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].numero_apparecchi_illuminanti_fissi`"
                            v-model="campo.numero_apparecchi_illuminanti_fissi"
                            :disabled="
                              campo.illuminazione_artificiale_fissa != 1
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].numero_apparecchi_illuminanti_fissi`"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Tipologia*</label
                          ><Field
                            type="text"
                            class="form-control"
                            :name="`campi[${index}].tipo_apparecchi_illuminanti_fissi`"
                            v-model="campo.tipo_apparecchi_illuminanti_fissi"
                            :disabled="
                              campo.illuminazione_artificiale_fissa != 1
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].tipo_apparecchi_illuminanti_fissi`"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Lux*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].lux_apparecchi_illuminanti_fissi`"
                            v-model="campo.lux_apparecchi_illuminanti_fissi"
                            :disabled="
                              campo.illuminazione_artificiale_fissa != 1
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].lux_apparecchi_illuminanti_fissi`"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >H dal suolo (m)*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].altezza_apparecchi_illuminanti_fissi`"
                            v-model="campo.altezza_apparecchi_illuminanti_fissi"
                            :disabled="
                              campo.illuminazione_artificiale_fissa != 1
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].altezza_apparecchi_illuminanti_fissi`"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Impianto di illuminazione artificiale aggiuntiva
                            del campo di gioco*</label
                          >

                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].illuminazione_artificiale_aggiuntiva`"
                            placeholder="-"
                            :value="campo.illuminazione_artificiale_aggiuntiva"
                            @changeSelect="
                              changeIlluminazioneArtificialeAggiuntivaCampo(
                                index,
                                $event
                              )
                            "
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Apparecchi illuminanti n.*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].numero_apparecchi_illuminanti_aggiuntivi`"
                            v-model="
                              campo.numero_apparecchi_illuminanti_aggiuntivi
                            "
                            :disabled="
                              campo.illuminazione_artificiale_aggiuntiva != 1
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].numero_apparecchi_illuminanti_aggiuntivi`"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Tipologia*</label
                          ><Field
                            type="text"
                            class="form-control"
                            :name="`campi[${index}].tipo_apparecchi_illuminanti_aggiuntivi`"
                            v-model="
                              campo.tipo_apparecchi_illuminanti_aggiuntivi
                            "
                            :disabled="
                              campo.illuminazione_artificiale_aggiuntiva != 1
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].tipo_apparecchi_illuminanti_aggiuntivi`"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Lux*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].lux_apparecchi_illuminanti_aggiuntivi`"
                            v-model="
                              campo.lux_apparecchi_illuminanti_aggiuntivi
                            "
                            :disabled="
                              campo.illuminazione_artificiale_aggiuntiva != 1
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].lux_apparecchi_illuminanti_aggiuntivi`"
                          />
                        </div>
                        <div class="mb-4 col-md-2">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >H dal suolo (m)*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].altezza_apparecchi_illuminanti_aggiuntivi`"
                            v-model="
                              campo.altezza_apparecchi_illuminanti_aggiuntivi
                            "
                            :disabled="
                              campo.illuminazione_artificiale_aggiuntiva != 1
                            "
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].altezza_apparecchi_illuminanti_aggiuntivi`"
                          />
                        </div>
                      </div>
                      <hr />
                      <p class="fw-bold text-gray-700 mb-0 pt-4 pb-1">
                        Installazioni per il pubblico:
                      </p>
                      <div class="row align-items-baseline">
                        <div class="col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Tribuna fissa*</label
                          >
                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].tribuna_fissa`"
                            placeholder="-"
                            :value="campo.tribuna_fissa"
                            @changeSelect="
                              changeTribunaFissaCampo(index, $event)
                            "
                          />
                        </div>
                        <div class="col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Numero tribune fisse*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].numero_tribune_fisse`"
                            v-model="campo.numero_tribune_fisse"
                            :disabled="campo.tribuna_fissa != 1"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].numero_tribune_fisse`"
                          />
                        </div>
                        <div class="col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Capienza posti a sedere tribune fisse*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].capienza_tribuna_fissa`"
                            v-model="campo.capienza_tribuna_fissa"
                            :disabled="campo.tribuna_fissa != 1"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].capienza_tribuna_fissa`"
                          />
                        </div>
                        <div class="col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Copertura*</label
                          >

                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].copertura_tribuna_fissa`"
                            placeholder="-"
                            :value="campo.copertura_tribuna_fissa"
                            @changeSelect="
                              setCoperturaTribunaFissa(index, $event)
                            "
                            :disabled="campo.tribuna_fissa != 1"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Tribuna mobile*</label
                          >

                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].tribuna_mobile`"
                            placeholder="-"
                            :value="campo.tribuna_mobile"
                            @changeSelect="
                              changeTribunaMobileCampo(index, $event)
                            "
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Numero tribune mobili*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].numero_tribune_mobili`"
                            v-model="campo.numero_tribune_mobili"
                            :disabled="campo.tribuna_mobile != 1"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].numero_tribune_mobili`"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Capienza posti a sedere tribune mobile*</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].capienza_tribuna_mobile`"
                            v-model="campo.capienza_tribuna_mobile"
                            :disabled="campo.tribuna_mobile != 1"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].capienza_tribuna_mobile`"
                          />
                        </div>
                      </div>
                      <div class="row align-items-baseline">
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >N. servizi igienici pubblico</label
                          >
                          <Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].numero_servizi_pubblico`"
                            v-model="campo.numero_servizi_pubblico"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].numero_servizi_pubblico`"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >n. lavabi</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].numero_lavabi_servizi_pubblico`"
                            v-model="campo.numero_lavabi_servizi_pubblico"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].numero_lavabi_servizi_pubblico`"
                          />
                        </div>
                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >n. WC</label
                          >
                          <Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].numero_wc_servizi_pubblico`"
                            v-model="campo.numero_wc_servizi_pubblico"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].numero_wc_servizi_pubblico`"
                          />
                        </div>

                        <div class="mb-4 col-md-3">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Abbattimento barriere architettoniche per il
                            pubblico*</label
                          >
                          <SelectInput
                            :options="optionsTrueFalse"
                            :name="`campi[${index}].accessibilita_pubblico`"
                            placeholder="-"
                            :value="campo.accessibilita_pubblico"
                            @changeSelect="
                              setAccessibilitaPubblico(index, $event)
                            "
                          />
                        </div>
                      </div>
                      <div
                        class="row align-items-baseline"
                        v-if="campo.id_disciplina === 4332"
                      >
                        <div class="mb-4 col-md-4">
                          <label
                            class="fw-bold text-gray-600"
                            label-for="descrizione"
                            >Campo singolare/doppio*</label
                          >
                          <SelectInput
                            :options="omologazioni_tipologia_campi_singoli"
                            :name="`campi[${index}].id_tipologia_campo_singolo`"
                            placeholder="-"
                            :value="campo.id_tipologia_campo_singolo"
                            @changeSelect="
                              changeCampoSingoloDoppio(index, $event)
                            "
                            :disabled="campo.id_disciplina != 4332"
                          />
                        </div>
                        <div
                          v-if="campo.id_tipologia_campo_singolo == 1"
                          class="mb-4 col-md-4"
                        >
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Distanza da riga singolare dal centro del palo da
                            singolare 1:</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].distanza_riga_singolare_1`"
                            v-model="campo.distanza_riga_singolare_1"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].distanza_riga_singolare_1`"
                          />
                        </div>
                        <div
                          class="mb-4 col-md-4"
                          v-if="campo.id_tipologia_campo_singolo == 1"
                        >
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Distanza da riga singolare dal centro del palo da
                            singolare 2:</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].distanza_riga_singolare_2`"
                            v-model="campo.distanza_riga_singolare_2"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].distanza_riga_singolare_2`"
                          />
                        </div>
                        <div
                          class="mb-4 col-md-2"
                          v-if="campo.id_tipologia_campo_singolo == 2"
                        >
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Distanza da riga doppio dal centro del palo da
                            doppio 1:</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].distanza_riga_doppio_1`"
                            v-model="campo.distanza_riga_doppio_1"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].distanza_riga_doppio_1`"
                          />
                        </div>
                        <div
                          class="mb-4 col-md-2"
                          v-if="campo.id_tipologia_campo_singolo == 2"
                        >
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Distanza da riga doppio dal centro del palo da
                            doppio 2:</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].distanza_riga_doppio_2`"
                            v-model="campo.distanza_riga_doppio_2"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].distanza_riga_doppio_2`"
                          />
                        </div>
                        <div
                          class="mb-4 col-md-2"
                          v-if="campo.id_tipologia_campo_singolo == 2"
                        >
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Larghezza corridoio 1:</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].larghezza_corridoio_1`"
                            v-model="campo.larghezza_corridoio_1"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].larghezza_corridoio_1`"
                          />
                        </div>
                        <div
                          class="mb-4 col-md-2"
                          v-if="campo.id_tipologia_campo_singolo == 2"
                        >
                          <label
                            class="fw-bold text-gray-600"
                            label-for="disciplina"
                            >Larghezza corridoio 2:</label
                          ><Field
                            type="number"
                            min="0"
                            class="form-control"
                            :name="`campi[${index}].larghezza_corridoio_2`"
                            v-model="campo.larghezza_corridoio_2"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            :name="`campi[${index}].larghezza_corridoio_2`"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </FieldArray>
              </div>

              <!--end::Card body-->
            </div>
          </div>
        </div>
      </div>
      <!--end::details View-->
      <div class="text-end">
        <button
          type="button"
          class="badge bg-light-warning text-warning border border-warning rounded blueFit fs-5 me-5"
          @click="salvaComeBozza(values)"
          :disabled="disableButton"
          v-if="
            impiantoDetail.bozza || !impiantoDetail.id_omologazione_impianti
          "
        >
          <span> Salva come bozza</span>
        </button>
        <button
          type="submit"
          class="badge bg-secondary rounded blueFit fs-5"
          :disabled="disableButton"
          v-if="
            !impiantoDetail.id_omologazione_impianti || impiantoDetail.bozza
          "
        >
          <span> Invia richiesta di Omologazione</span>
        </button>
        <button
          type="submit"
          class="badge bg-secondary rounded blueFit fs-5"
          :disabled="disableButton"
          v-if="
            impiantoDetail.id_omologazione_impianti &&
            impiantoDetail.puo_modificare_dati &&
            !impiantoDetail.bozza
          "
        >
          <span> Salva Modifiche</span>
        </button>
      </div>
    </Form>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
//import services from "../../../axios/dbManag";

import { Form, ErrorMessage, Field, FieldArray } from "vee-validate";
//import * as yup from "yup";

//import TextInput from "../utility/inputs/TextInput.vue";
import SelectInput from "../utility/inputs/SelectInput.vue";
import SelectInput2 from "../utility/inputs/SelectInput_v03_NoSimilar.vue";
//import List from "@/components/components-fit/utility/List.vue";
import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import { useStore } from "vuex";

import { useRouter } from "vue-router";
import {
  detailImpiantoOmologazioneV2,
  addOmologazioneV2,
  editOmologazioneV2,
  inCaricoSiNoOmologazione,
  attesaVerificaOmologazione,
  approvaOmologazione,
  rifiutaOmologazione,
  annullaApprovazioneOmologazione,
} from "@/requests/impiantiOmologazione";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";
import Multiselect from "@vueform/multiselect";
import { fromUTCtoISODate, format } from "@/composables/formatDate";
import { schema } from "@/schemas/schemaOmologazione";

import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import readOnlyElements from "@/composables/readOnlyElements";

export default defineComponent({
  name: "add-omologazione",
  emits: ["changeStato"],
  props: {
    id_imp: {
      type: Number,
      required: true,
    },
    id_omo: {
      type: Number,
      required: true,
    },
    societa_nome: {
      type: String,
    },
    id_soc: {
      type: Number,
      required: true,
    },
    fromSocieta: {
      type: Boolean,
      required: true,
    },
    fromImpianto: {
      type: Boolean,
      required: true,
    },
    fromEnte: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Datepicker,
    //TextInput,
    SelectInput,
    Form,
    ErrorMessage,
    Field,
    FieldArray,
    //fromUTCtoISODate,
    //List,
    ListSocieta,
    Multiselect,
    Loading,
    SelectInput2,
  },
  setup(props, { emit }) {
    const router = useRouter();

    /* const richiedente = ref(null);
    const setRichiedente = (event) => {
      richiedente.value = event.target.value;
    }; */

    const enteProprietario = ref(null);
    const setEnteProprietario = (event) => {
      enteProprietario.value = event.target.value;
    };

    const enteGestore = ref(null);
    const idEnteGestore = ref(0);
    const setEnteGestore = (event) => {
      enteGestore.value = event.target.value;
    };

    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    // lookups
    const omologazioni_tipologia = computed(() =>
      store.getters.getStateFromName("omologazioni_tipologia")
    );
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const omologazioni_livelli_impianti = computed(() =>
      store.getters.getStateFromName("omologazioni_livelli_impianti")
    );
    const omologazioni_disponibilita_ente_impianti = computed(() =>
      store.getters.getStateFromName("omologazioni_disponibilita_ente_impianti")
    );
    const omologazioni_durata_disponibilita_impianti = computed(() =>
      store.getters.getStateFromName(
        "omologazioni_durata_disponibilita_impianti"
      )
    );
    const omologazioni_tipo_gara = computed(() =>
      store.getters.getStateFromName("omologazioni_tipo_gara")
    );
    const omologazioni_tipologia_vetro_campi = computed(() =>
      store.getters.getStateFromName("omologazioni_tipologia_vetro_campi")
    );
    const omologazioni_tipo_impianti_campo = computed(() =>
      store.getters.getStateFromName("omologazioni_tipo_impianti_campo")
    );
    const omologazioni_tipo_copertura_campi = computed(() =>
      store.getters.getStateFromName("omologazioni_tipo_copertura_campi")
    );
    const omologazioni_campi_coperti = computed(() =>
      store.getters.getStateFromName("omologazioni_campi_coperti")
    );
    const omologazioni_tipologia_copertura_impianti = computed(() =>
      store.getters.getStateFromName(
        "omologazioni_tipologia_copertura_impianti"
      )
    );
    const impianti_campi_tipo_pavimentazione = computed(() =>
      store.getters.getStateFromName("impianti_campi_tipo_pavimentazione")
    );
    const omologazioni_tipologia_campi_singoli = computed(() =>
      store.getters.getStateFromName("omologazioni_tipologia_campi_singoli")
    );

    const keys = ref(
      "ot|d|oli|odei|oddi|ota|otvc|otic|otcc|occ|otci|otpc|otcs"
    );
    if (
      !omologazioni_tipologia.value ||
      !discipline.value ||
      !omologazioni_livelli_impianti.value ||
      !omologazioni_disponibilita_ente_impianti.value ||
      !omologazioni_durata_disponibilita_impianti.value ||
      !omologazioni_tipo_gara.value ||
      !omologazioni_tipologia_vetro_campi.value ||
      !omologazioni_tipo_impianti_campo.value ||
      !omologazioni_tipo_copertura_campi.value ||
      !omologazioni_campi_coperti.value ||
      !omologazioni_tipologia_copertura_impianti.value ||
      !impianti_campi_tipo_pavimentazione.value ||
      !omologazioni_tipologia_campi_singoli.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }
    const discipline_impianto = computed(() =>
      store.getters.getStateFromName("discipline_impianto")
    );
    // if (!discipline_impianto.value) {
    store.dispatch("setStoreData", {
      keys: { id_Impianto: props.id_imp },
      apiLink: globalApi.DISCIPLINE_IMPIANTI,
    });
    // }

    const impiantoDetail = ref({});
    const campiLocked = ref([]);
    const id_impianto = ref(props.id_imp);
    const id_omologazione = ref(props.id_omo);
    const isLoaded = ref(false);
    const isError = ref(false);
    const disciplinaSelected = ref(null);
    const selIdLivelloOmologazione = ref(null);
    const selTipologiaOmologazione = ref(null);
    const data_inizio = ref(null);
    const data_fine = ref(null);
    const defaultStagione = computed(() => store.getters.defaultStagione);
    const ente_gestore = ref("");
    const id_ente_gestore = ref(null);
    const selDisponibilitaEnte = ref(null);
    const disponibilita_ente_altro = ref("");
    const selDurataDisponibilitaEnte = ref(null);
    const mesi_disponibilita = ref(null);
    const anni_disponibilita = ref(null);
    const selTipologieGara = ref([]);
    const id_tipologia_gara = ref("");
    const spogliatoi_accessibilita = ref(null);
    const ristoro = ref(null);
    const ristoro_con_addetto = ref(null);
    const sala_riunioni = ref(null);
    const palestra_attivita_fisica = ref(null);
    const servizi_ristoro_automatici = ref(null);
    const spazi_riposo_giocatori = ref(null);
    const attivita_commerciali = ref(null);
    const locale_ristoro = ref(null);
    const parcheggi = ref(null);
    const spazio_verde = ref(null);
    const ristorante = ref(null);
    const altezza_libera_minima_tennis = ref(null);
    const altezza_libera_minima_padel = ref(null);
    const id_tipologia_vetro = ref(null);
    const tipologia_copertura = ref(null);
    const presenza_climatizzazione = ref(null);
    const selectedTipologieGare = ref([]);

    const error = ref(false);

    const loadDetails = async (id_impianto, id_disciplina, id_omologazione) => {
      console.log("Chiamata dettaglio");
      isLoaded.value = false;
      await detailImpiantoOmologazioneV2(
        id_impianto,
        id_disciplina,
        id_omologazione
      ).then((res) => {
        isLoaded.value = true;
        if (res.results) {
          impiantoDetail.value = res.results[0];
          console.log(impiantoDetail.value.stato_omologazione);
          emit("changeStato", impiantoDetail.value.stato_omologazione);
          if (!impiantoDetail.value.puo_modificare_dati) {
            setTimeout(() => {
              readOnlyElements(
                impiantoDetail.value.puo_modificare_stato
                  ? "azioneOmologazione"
                  : ""
              );
            }, 500);
          }
          selTipologiaOmologazione.value = selTipologiaOmologazione.value
            ? selTipologiaOmologazione.value
            : impiantoDetail.value.id_tipologia_omologazione;
          selDisponibilitaEnte.value = selDisponibilitaEnte.value
            ? selDisponibilitaEnte.value
            : impiantoDetail.value.id_disponibilita_ente;
          selDurataDisponibilitaEnte.value = selDurataDisponibilitaEnte.value
            ? selDurataDisponibilitaEnte.value
            : impiantoDetail.value.id_durata_disponibilita;
          spogliatoi_accessibilita.value =
            impiantoDetail.value.spogliatoi_accessibilita;
          ristoro_con_addetto.value = impiantoDetail.value.ristoro_con_addetto;
          ristoro.value = impiantoDetail.value.ristoro;
          servizi_ristoro_automatici.value =
            impiantoDetail.value.servizi_ristoro_automatici;
          locale_ristoro.value = impiantoDetail.value.locale_ristoro;
          ristorante.value = impiantoDetail.value.ristorante;
          spazi_riposo_giocatori.value =
            impiantoDetail.value.spazi_riposo_giocatori;
          attivita_commerciali.value =
            impiantoDetail.value.attivita_commerciali;
          sala_riunioni.value = impiantoDetail.value.sala_riunioni;
          palestra_attivita_fisica.value =
            impiantoDetail.value.palestra_attivita_fisica;
          spazio_verde.value = impiantoDetail.value.spazio_verde;
          parcheggi.value = impiantoDetail.value.parcheggi;
          if (impiantoDetail.value.id_tipologia_gara != null) {
            selectedTipologieGare.value = [];
            let arrTmp = impiantoDetail.value.id_tipologia_gara.split(",");
            let i;
            for (i in arrTmp) {
              selectedTipologieGare.value.push(parseInt(arrTmp[i]));
              inputElement(selectedTipologieGare.value);
            }
          }
          if (impiantoDetail.value.id_disciplina != null) {
            disciplinaSelected.value = impiantoDetail.value.id_disciplina;
          }
        } else {
          error.value = true;
        }
      });
    };

    const changeTipologiaOmologazione = (id) => {
      selTipologiaOmologazione.value = parseInt(id);
      data_inizio.value = null;
      data_fine.value = null;
    };

    const changeDisciplinaOmologazione = (id) => {
      disciplinaSelected.value = parseInt(id);
      loadDetails(
        id_impianto.value,
        disciplinaSelected.value,
        id_omologazione.value
      );
    };

    const changeIdLivelloOmologazione = async (id) => {
      selIdLivelloOmologazione.value = parseInt(id);
      // await loadDetails(
      //   id_impianto.value,
      //   disciplinaSelected.value,
      //   id_omologazione.value
      // );
    };

    const id_societa = ref(null);
    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getEntiNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };
    const setSocieta = (e) => {
      ente_gestore.value = null;
      id_ente_gestore.value = null;
      impiantoDetail.value.ente_gestore = null;
      impiantoDetail.value.id_ente_gestore = null;
      if (e && e != null) {
        ente_gestore.value = e.name;
        id_ente_gestore.value = e.value;
        impiantoDetail.value.ente_gestore = e.name;
        impiantoDetail.value.id_ente_gestore = e.value;
      }
    };

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          id_societa.value = null;
        }
      }
    );

    const changeDisponibilitaEnte = (id) => {
      selDisponibilitaEnte.value = parseInt(id);
      impiantoDetail.value.disponibilita_ente_altro = "";
    };

    const changeDurataDisponibilitaEnte = (id) => {
      selDurataDisponibilitaEnte.value = parseInt(id);
      impiantoDetail.value.mesi_disponibilita = null;
      impiantoDetail.value.anni_disponibilita = null;
    };

    const changeTipologiaCoperturaCampo = (index, id) => {
      impiantoDetail.value.campi[index].id_tipo_campo_omologazione =
        parseInt(id);
      impiantoDetail.value.campi[index].presenza_climatizzazione = null;
      impiantoDetail.value.campi[index].tipo_copertura = null;
      impiantoDetail.value.campi[index].altezza_laterale_1 = null;
      impiantoDetail.value.campi[index].altezza_laterale_2 = null;
      impiantoDetail.value.campi[index].altezza_fondo_1 = null;
      impiantoDetail.value.campi[index].altezza_fondo_2 = null;
      impiantoDetail.value.campi[index].altezza_massima = null;
      changeTipologiaCoperturaCampoCoperto(index, null);
      impiantoDetail.value.campi[index].altezza_centro_rete = null;
      impiantoDetail.value.campi[index].altezza_linee_laterali = null;
      impiantoDetail.value.campi[index].altezza_linea_fondo = null;
      impiantoDetail.value.campi[index].altezza_angoli = null;
      changePeriodoCoperturaCampo(index, null);
    };

    const changeTipologiaCoperturaCampoCoperto = (index, id) => {
      impiantoDetail.value.campi[index].tipologie_campi_coperti = parseInt(id);
      impiantoDetail.value.campi[index].aperture_scorrevoli_laterali = null;
    };

    const changePeriodoCoperturaCampo = (index, id) => {
      impiantoDetail.value.campi[index].tipologia_copertura = parseInt(id);
      impiantoDetail.value.campi[index].data_inizio_copertura = null;
      impiantoDetail.value.campi[index].data_fine_copertura = null;
    };

    const setIlluminazioneEmerg = (index, event) => {
      impiantoDetail.value.campi[index].presenza_illuminazione_emergenza =
        event;
    };

    const setWiFi = (index, event) => {
      impiantoDetail.value.campi[index].presenza_wifi = event;
    };

    const setAntincendio = (index, event) => {
      impiantoDetail.value.campi[index].presenza_impianto_antincendio = event;
    };

    const setImpSonoro = (index, event) => {
      impiantoDetail.value.campi[index].presenza_impianto_sonoro = event;
    };

    const setTipoPavimentazione = (index, event) => {
      impiantoDetail.value.campi[index].id_tipo_pavimentazione =
        parseInt(event);
    };

    const setClimatizzazione = (index, event) => {
      impiantoDetail.value.campi[index].presenza_climatizzazione = event;
    };

    const setAccessibileWeelchair = (index, event) => {
      impiantoDetail.value.campi[index].accessibile_wheelchair = event;
    };

    const setSediaArbitro = (index, event) => {
      impiantoDetail.value.campi[index].sedia_arbitro = event;
    };

    const setAccessibilitaPubblico = (index, event) => {
      impiantoDetail.value.campi[index].accessibilita_pubblico = event;
    };

    const setCoperturaTribunaFissa = (index, event) => {
      impiantoDetail.value.campi[index].copertura_tribuna_fissa = event;
    };

    const changeIlluminazioneArtificialeFissaCampo = (index, event) => {
      impiantoDetail.value.campi[index].illuminazione_artificiale_fissa = event;
      impiantoDetail.value.campi[index].numero_apparecchi_illuminanti_fissi =
        null;
      impiantoDetail.value.campi[index].tipo_apparecchi_illuminanti_fissi =
        null;
      impiantoDetail.value.campi[index].lux_apparecchi_illuminanti_fissi = null;
      impiantoDetail.value.campi[index].altezza_apparecchi_illuminanti_fissi =
        null;
    };

    const changeIlluminazioneArtificialeAggiuntivaCampo = (index, event) => {
      impiantoDetail.value.campi[index].illuminazione_artificiale_aggiuntiva =
        event;
      impiantoDetail.value.campi[
        index
      ].numero_apparecchi_illuminanti_aggiuntivi = null;
      impiantoDetail.value.campi[index].tipo_apparecchi_illuminanti_aggiuntivi =
        null;
      impiantoDetail.value.campi[index].lux_apparecchi_illuminanti_aggiuntivi =
        null;
      impiantoDetail.value.campi[
        index
      ].altezza_apparecchi_illuminanti_aggiuntivi = null;
    };

    const changeTribunaFissaCampo = (index, event) => {
      impiantoDetail.value.campi[index].tribuna_fissa = event;
      impiantoDetail.value.campi[index].numero_tribune_fisse = null;
      impiantoDetail.value.campi[index].capienza_tribuna_fissa = null;
      impiantoDetail.value.campi[index].copertura_tribuna_fissa = null;
    };

    const changeTribunaMobileCampo = (index, event) => {
      impiantoDetail.value.campi[index].tribuna_mobile = event;
      impiantoDetail.value.campi[index].numero_tribune_mobili = null;
      impiantoDetail.value.campi[index].capienza_tribuna_mobile = null;
    };

    const changeCampoSingoloDoppio = (index, id) => {
      impiantoDetail.value.campi[index].id_tipologia_campo_singolo =
        parseInt(id);
      impiantoDetail.value.campi[index].distanza_riga_singolare_1 = null;
      impiantoDetail.value.campi[index].distanza_riga_singolare_2 = null;
      impiantoDetail.value.campi[index].distanza_riga_doppio_1 = null;
      impiantoDetail.value.campi[index].distanza_riga_doppio_2 = null;
    };

    const changeYTest = (index, id) => {
      impiantoDetail.value.campi[index].accessibilita_pubblico = parseInt(id);
    };

    const inputElement = (selected) => {
      id_tipologia_gara.value = "";
      id_tipologia_gara.value = selected.join(",");
    };

    loadDetails(
      id_impianto.value,
      disciplinaSelected.value,
      id_omologazione.value
    );

    const alertCampiObb = ref(false);
    const disableButton = ref(false);
    const isLoading = ref(false);
    const onSubmit = (values) => {
      if (!values.bozza) {
        values = {
          ...values,
          id_impianto: id_impianto.value,
          id_societa: id_societa.value,
          id: impiantoDetail.value.id_omologazione_impianti,
          bozza: values.bozza ? values.bozza : false,
        };
      }

      alertCampiObb.value = false;
      isLoading.value = true;
      disableButton.value = true;
      if (impiantoDetail.value.id_omologazione_impianti) {
        values.id_tipologia_gara = id_tipologia_gara.value;
        editOmologazioneV2(values).then((res) => {
          isLoading.value = false;
          disableButton.value = false;
          if (res.status == 200) {
            alertSuccess("Omologazione modificata correttamente");
            loadDetails(
              id_impianto.value,
              disciplinaSelected.value,
              id_omologazione.value
            );
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        });
      }
      if (!impiantoDetail.value.id_omologazione_impianti) {
        values.id_tipologia_gara = id_tipologia_gara.value;
        addOmologazioneV2(values).then((res) => {
          isLoading.value = false;
          disableButton.value = false;
          if (res.status == 200) {
            alertSuccess("Omologazione aggiunta correttamente");
            if (props.fromSocieta == true) {
              router.push(
                `/enti-aggregati/dettaglio-ente/impianti-ente/omologazioni-ente/${props.id_soc}/${props.id_imp}`
              );
            }
            if (props.fromImpianto == true) {
              router.push(`/impianti/omologazioni-impianto/${props.id_imp}`);
            }
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        });
      }
    };

    const onInvalidSubmit = (values) => {
      alertCampiObb.value = true;
      alertFailed("Compila i campi obbligatori");
      //window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const prendiInCarico = (id) => {
      isLoading.value = true;
      inCaricoSiNoOmologazione(id, true).then((res) => {
        if (res.status == 200) {
          alertSuccess("Omologazione presa in carico.");
          loadDetails(
            id_impianto.value,
            disciplinaSelected.value,
            id_omologazione.value
          );
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        isLoading.value = false;
      });
    };

    const annullaPresaInCarico = (id) => {
      isLoading.value = true;
      inCaricoSiNoOmologazione(id, false).then((res) => {
        if (res.status == 200) {
          alertSuccess("Presa in carico annullata.");
          loadDetails(
            id_impianto.value,
            disciplinaSelected.value,
            id_omologazione.value
          );
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        isLoading.value = false;
      });
    };

    const inAttesaDiVerifica = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await attesaVerificaOmologazione(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Operazione effettuata correttamente.");
              loadDetails(
                id_impianto.value,
                disciplinaSelected.value,
                id_omologazione.value
              );
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const annullaApprovazione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Conferma",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await annullaApprovazioneOmologazione(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Operazione effettuata correttamente.");
              loadDetails(
                id_impianto.value,
                disciplinaSelected.value,
                id_omologazione.value
              );
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const approva = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'omologazione verrà approvata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Approva",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await approvaOmologazione(id, id_impianto.value).then((res) => {
            if (res.status == 200) {
              alertSuccess("Omologazione approvata correttamente.");
              loadDetails(
                id_impianto.value,
                disciplinaSelected.value,
                id_omologazione.value
              );
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const rifiuta = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'omologazione verrà rifiutata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Rifiuta",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await rifiutaOmologazione(id, id_impianto.value).then((res) => {
            if (res.status == 200) {
              alertSuccess("Omologazione rifiutata correttamente.");
              loadDetails(
                id_impianto.value,
                disciplinaSelected.value,
                id_omologazione.value
              );
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const salvaComeBozza = (values) => {
      if (!values.id_disciplina) {
        alertFailed("Inserire la disciplina");
        return;
      }
      values = {
        ...values,
        id_impianto: id_impianto.value,
        id_societa: id_societa.value,
        id: props.id_omo
          ? impiantoDetail.value.id_omologazione_impianti
          : undefined,
        bozza: true,
      };
      onSubmit(values, true);
    };

    return {
      salvaComeBozza,
      impiantoDetail,
      id_impianto,
      id_omologazione,
      campiLocked,
      isLoaded,
      isError,
      disciplinaSelected,
      selIdLivelloOmologazione,
      omologazioni_tipologia,
      changeTipologiaOmologazione,
      selTipologiaOmologazione,
      data_inizio,
      data_fine,
      format,
      stagioni: computed(() => store.getters.stagioni),
      defaultStagione,
      discipline,
      changeDisciplinaOmologazione,
      omologazioni_livelli_impianti,
      loadDetails,
      ente_gestore,
      id_ente_gestore,
      societa: computed(() => store.getters.societaNomeCodAff),
      getSocieta,
      resetSocieta,
      setSocieta,
      omologazioni_disponibilita_ente_impianti,
      changeDisponibilitaEnte,
      selDisponibilitaEnte,
      disponibilita_ente_altro,
      omologazioni_durata_disponibilita_impianti,
      changeDurataDisponibilitaEnte,
      selDurataDisponibilitaEnte,
      mesi_disponibilita,
      anni_disponibilita,
      omologazioni_tipo_gara,
      selTipologieGara,
      inputElement,
      id_tipologia_gara,
      optionsTrueFalse: [
        { id: false, label: "No" },
        { id: true, label: "Si" },
      ],
      spogliatoi_accessibilita,
      ristoro,
      ristoro_con_addetto,
      sala_riunioni,
      palestra_attivita_fisica,
      servizi_ristoro_automatici,
      spazi_riposo_giocatori,
      attivita_commerciali,
      locale_ristoro,
      parcheggi,
      spazio_verde,
      ristorante,
      altezza_libera_minima_tennis,
      altezza_libera_minima_padel,
      omologazioni_tipologia_vetro_campi,
      id_tipologia_vetro,
      omologazioni_tipo_impianti_campo,
      tipologia_copertura,
      changeTipologiaCoperturaCampo,
      presenza_climatizzazione,
      omologazioni_tipo_copertura_campi,
      omologazioni_campi_coperti,
      changeTipologiaCoperturaCampoCoperto,
      omologazioni_tipologia_copertura_impianti,
      changePeriodoCoperturaCampo,
      impianti_campi_tipo_pavimentazione,
      changeIlluminazioneArtificialeFissaCampo,
      changeIlluminazioneArtificialeAggiuntivaCampo,
      changeTribunaFissaCampo,
      changeTribunaMobileCampo,
      omologazioni_tipologia_campi_singoli,
      changeYTest,
      changeIdLivelloOmologazione,
      changeCampoSingoloDoppio,
      fromUTCtoISODate,
      /* richiedente,
      setRichiedente, */
      enteProprietario,
      setEnteProprietario,
      enteGestore,
      setEnteGestore,
      idEnteGestore,
      selectedTipologieGare,
      schema,

      onInvalidSubmit,
      onSubmit,
      alertCampiObb,
      disableButton,
      isLoading,
      error,

      setIlluminazioneEmerg,
      setWiFi,
      setAntincendio,
      setImpSonoro,
      setTipoPavimentazione,
      setClimatizzazione,
      setAccessibileWeelchair,
      setSediaArbitro,
      setAccessibilitaPubblico,
      setCoperturaTribunaFissa,
      discipline_impianto,

      prendiInCarico,
      annullaPresaInCarico,
      inAttesaDiVerifica,
      approva,
      rifiuta,
      annullaApprovazione,
    };
  },
  data() {
    return {};
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
