<template>
  <div class="row">
    <div class="col-6">
      <a
        href=""
        data-bs-toggle="modal"
        data-bs-target="#modal_doc_impianti"
        class="btn btn-light text-gray-700 fs-6 text-start"
        @click="
          id_omologazione_docs = id_omo;
          refresh = !refresh;
        "
      >
        <i class="bi bi-files fs-6 text-gray-700"></i>Documenti omologazione
      </a>
    </div>
    <div class="col-md-6 text-end">
      <router-link
        :to="`/omologazioni/${
          id_stato == 5
            ? 'richieste'
            : id_stato == 6
            ? 'prese-in-carico'
            : id_stato == 2
            ? 'in-attesa'
            : id_stato == 1
            ? 'approvate'
            : id_stato == 3
            ? 'rifiutate'
            : ''
        }`"
        class="btn btn-light text-gray-700 fs-6 text-start"
      >
        <i class="bi bi-arrow-left fs-6 text-gray-700"></i> Lista omologazioni
      </router-link>
    </div>
  </div>
  <br />
  <AddOmologazione
    :id_imp="id_impianto"
    :id_omo="id_omo"
    :fromSocieta="true"
    @changeStato="changeStato"
  />
  <ModalDocImpianti
    :id_Omologazione="id_omologazione_docs"
    :id_stato="id_stato"
    :refresh="refresh"
  />
</template>

<script>
import { onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AddOmologazione from "@/components/components-fit/impianti/AddOmologazione.vue";
import { useRoute } from "vue-router";
import ModalDocImpianti from "@/components/components-fit/impianti/ModalDocImpianti";

export default {
  components: { AddOmologazione, ModalDocImpianti },
  setup() {
    const route = useRoute(); // prende id
    const id_societa = ref(route.params.id);
    const id_impianto = ref(route.params.id_impianto);
    const id_omo = ref(route.params.id_omo);
    const id_omologazione_docs = ref(null);
    const id_stato = ref(route.params.id_stato);
    const changeStato = (value) => {
      id_stato.value = value;
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Dettaglio Omologazione", [
        "Lista Omologazioni",
      ]);
    });

    const refresh = ref(false);

    return {
      id_societa,
      id_impianto,
      id_omo,
      id_omologazione_docs,
      changeStato,
      id_stato,
      refresh,
    };
  },
};
</script>

<style></style>
