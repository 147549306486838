import * as yup from "yup";

let id_disciplina;
export const schema = yup.object().shape({
  id_tipologia_gara: yup.string().required("Obbligatorio").nullable(),
  referente: yup.string().required("Obbligatorio").nullable(),
  richiedente: yup.string().required("Obbligatorio").nullable(),
  id_tipologia_omologazione: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Obbligatorio")
    .nullable(),
  data_inizio: yup.string().when("id_tipologia_omologazione", {
    is: (id_tipologia_omologazione) => id_tipologia_omologazione === 2,
    then: yup.string().required("Obbligatorio").nullable(),
    otherwise: yup.string().nullable(),
  }),
  data_fine: yup.string().when("id_tipologia_omologazione", {
    is: (id_tipologia_omologazione) => id_tipologia_omologazione === 2,
    then: yup.string().required("Obbligatorio").nullable(),
    otherwise: yup.string().nullable(),
  }),
  data_costruzione_impianto: yup.string().nullable(),
  ente_proprietario: yup.string().required("Obbligatorio").nullable(),
  anno: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Obbligatorio")
    .nullable(),
  id_disciplina: yup
    .number()
    .transform((value) => {
      id_disciplina = value;
      return isNaN(value) ? undefined : value;
    })
    .required("Obbligatorio")
    .nullable(),
  stato_omologazione: yup
    .number()
    .nullable()
    .transform((value) => (isNaN(value) ? undefined : value)),
  id_livello_omologazione: yup.number().when("stato_omologazione", {
    is: (stato_omologazione) => stato_omologazione == 2,
    then: yup
      .number()
      .required("Obbligatorio")
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
    otherwise: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? undefined : value)),
  }),
  id_disponibilita_ente: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Obbligatorio")
    .nullable(),
  disponibilita_ente_altro: yup.string().when("id_disponibilita_ente", {
    is: (id_disponibilita_ente) => id_disponibilita_ente === 5,
    then: yup.string().required("Obbligatorio").nullable(),
    otherwise: yup.string().nullable(),
  }),
  id_durata_disponibilita: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Obbligatorio")
    .nullable(),
  campi: yup.array().of(
    yup.object({
      // numero_wc_servizi_pubblico: yup.number().required("Obbligatorio").nullable(),
      // distanza_linea_battuta_1: yup.test(
      //   "checkIdDisciplina",
      //   "Campo Obbligatorio",
      //   function (value) {
      //     return id_disciplina === 172;
      //   }
      // ),
      // PARAMETRI CAMPI PADEL E TENNIS
      distanza_linea_battuta_1: yup.number().when("id_disciplina", {
        is: () => {
          console.log("idd omolog", id_disciplina);
          return id_disciplina === 172 || id_disciplina === 4332;
        },
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      distanza_linea_battuta_2: yup.number().when("id_disciplina", {
        is: () => {
          console.log("idd omolog", id_disciplina);
          return id_disciplina === 172 || id_disciplina === 4332;
        },
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      // PARAMETRI SOLO CAMPI PADEL
      altezza_libera_minima_padel: yup.boolean().when("id_disciplina", {
        is: () => {
          console.log("idd omolog", id_disciplina);
          return id_disciplina === 172;
        },
        then: yup
          .boolean()
          .required("Obbligatorio")
          .typeError("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .boolean()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      distanza_laterale_battuta_1: yup.number().when("id_disciplina", {
        is: () => {
          console.log("idd omolog", id_disciplina);
          return id_disciplina === 172;
        },
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      distanza_laterale_battuta_2: yup.number().when("id_disciplina", {
        is: () => {
          console.log("idd omolog", id_disciplina);
          return id_disciplina === 172;
        },
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      id_tipologia_vetro: yup.number().when("id_disciplina", {
        is: () => {
          console.log("idd omolog", id_disciplina);
          return id_disciplina === 172;
        },
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      spessore_vetro: yup.number().when("id_disciplina", {
        is: () => {
          console.log("idd omolog", id_disciplina);
          return id_disciplina === 172;
        },
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      // PARAMETRI SOLO CAMPI TENNIS
      altezza_libera_minima_tennis: yup.boolean().when("id_disciplina", {
        is: () => {
          console.log("idd omolog", id_disciplina);
          return id_disciplina === 4332;
        },
        then: yup
          .boolean()
          .required("Obbligatorio")
          .typeError("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .boolean()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      // PARAMETRI SOLO CAMPI TENNIS COPERTI id_tipo_campo_omologazione == 2
      altezza_centro_rete: yup.number().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => {
          return id_disciplina === 4332 && id_tipo_campo_omologazione == 2;
        },
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      altezza_linee_laterali: yup.number().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => {
          return id_disciplina === 4332 && id_tipo_campo_omologazione == 2;
        },
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      altezza_linea_fondo: yup.number().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => {
          return id_disciplina === 4332 && id_tipo_campo_omologazione == 2;
        },
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      altezza_angoli: yup.number().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => {
          return id_disciplina === 4332 && id_tipo_campo_omologazione == 2;
        },
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      descrizione_copertura: yup.string().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => {
          console.log(
            "idd omolog",
            id_disciplina,
            "id_tipo_campo_omologazione",
            id_tipo_campo_omologazione
          );
          return id_disciplina === 4332 && id_tipo_campo_omologazione == 2;
        },
        then: yup.string().required("Obbligatorio").nullable(),

        otherwise: yup.string().nullable(),
      }),
      // FINE CAMPI AGGIUNTI IL 24/08/2023
      id_campo: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      nome_campo: yup.string().required("Obbligatorio").nullable(),
      disciplina: yup.string().required("Obbligatorio").nullable(),
      lunghezza: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      larghezza: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      out_laterali_sinistro: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      out_laterali_destro: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      out_fondo_sinistro: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      out_fondo_destro: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      id_tipo_campo_omologazione: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      presenza_climatizzazione: yup
        .boolean()
        .when("id_tipo_campo_omologazione", {
          is: (id_tipo_campo_omologazione) => id_tipo_campo_omologazione === 2,
          then: yup.boolean().required("Obbligatorio").nullable(),
          otherwise: yup.boolean().nullable(),
        }),
      altezza_laterale_1: yup.number().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => id_tipo_campo_omologazione === 2,
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      altezza_laterale_2: yup.number().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => id_tipo_campo_omologazione === 2,
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      altezza_fondo_1: yup.number().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => id_tipo_campo_omologazione === 2,
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      altezza_fondo_2: yup.number().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => id_tipo_campo_omologazione === 2,
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      altezza_massima: yup.number().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => id_tipo_campo_omologazione === 2,
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),

        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      presenza_wifi: yup.boolean().required("Obbligatorio").nullable(),
      presenza_illuminazione_emergenza: yup
        .boolean()
        .required("Obbligatorio")
        .nullable(),
      presenza_impianto_antincendio: yup
        .boolean()
        .required("Obbligatorio")
        .nullable(),
      presenza_impianto_sonoro: yup
        .boolean()
        .required("Obbligatorio")
        .nullable(),
      id_tipo_pavimentazione: yup
        .number()
        .required("Obbligatorio")
        .nullable()
        .transform((value) => (isNaN(value) ? undefined : value)),
      illuminazione_artificiale_fissa: yup
        .boolean()
        .required("Obbligatorio")
        .nullable(),
      numero_apparecchi_illuminanti_fissi: yup
        .number()
        .when("illuminazione_artificiale_fissa", {
          is: (illuminazione_artificiale_fissa) =>
            illuminazione_artificiale_fissa === true,
          then: yup
            .number()
            .required("Obbligatorio")
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
          otherwise: yup
            .number()
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
        }),

      tipo_apparecchi_illuminanti_fissi: yup
        .string()
        .when("illuminazione_artificiale_fissa", {
          is: (illuminazione_artificiale_fissa) =>
            illuminazione_artificiale_fissa === true,
          then: yup.string().required("Obbligatorio").nullable(),
          otherwise: yup.string().nullable(),
        }),
      lux_apparecchi_illuminanti_fissi: yup
        .number()
        .when("illuminazione_artificiale_fissa", {
          is: (illuminazione_artificiale_fissa) =>
            illuminazione_artificiale_fissa === true,
          then: yup
            .number()
            .required("Obbligatorio")
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
          otherwise: yup
            .number()
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
        }),
      altezza_apparecchi_illuminanti_fissi: yup
        .number()
        .when("illuminazione_artificiale_fissa", {
          is: (illuminazione_artificiale_fissa) =>
            illuminazione_artificiale_fissa === true,
          then: yup
            .number()
            .required("Obbligatorio")
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
          otherwise: yup
            .number()
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
        }),
      illuminazione_artificiale_aggiuntiva: yup
        .boolean()
        .required("Obbligatorio")
        .nullable(),
      numero_apparecchi_illuminanti_aggiuntivi: yup
        .number()
        .when("illuminazione_artificiale_aggiuntiva", {
          is: (illuminazione_artificiale_aggiuntiva) =>
            illuminazione_artificiale_aggiuntiva === true,
          then: yup
            .number()
            .required("Obbligatorio")
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
          otherwise: yup
            .number()
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
        }),
      tipo_apparecchi_illuminanti_aggiuntivi: yup
        .string()
        .when("illuminazione_artificiale_aggiuntiva", {
          is: (illuminazione_artificiale_aggiuntiva) =>
            illuminazione_artificiale_aggiuntiva === true,
          then: yup.string().required("Obbligatorio").nullable(),
          otherwise: yup.string().nullable(),
        }),
      lux_apparecchi_illuminanti_aggiuntivi: yup
        .number()
        .when("illuminazione_artificiale_aggiuntiva", {
          is: (illuminazione_artificiale_aggiuntiva) =>
            illuminazione_artificiale_aggiuntiva === true,
          then: yup
            .number()
            .required("Obbligatorio")
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
          otherwise: yup
            .number()
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
        }),
      altezza_apparecchi_illuminanti_aggiuntivi: yup
        .number()
        .when("illuminazione_artificiale_aggiuntiva", {
          is: (illuminazione_artificiale_aggiuntiva) =>
            illuminazione_artificiale_aggiuntiva === true,
          then: yup
            .number()
            .required("Obbligatorio")
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
          otherwise: yup
            .number()
            .nullable()
            .transform((value) => (isNaN(value) ? undefined : value)),
        }),
      accessibile_wheelchair: yup.boolean().required("Obbligatorio").nullable(),
      sedia_arbitro: yup.boolean().when("disciplina", {
        is: (disciplina) => disciplina != "Padel",
        then: yup
          .boolean()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
        otherwise: yup.boolean().nullable(),
      }),
      tribuna_fissa: yup.boolean().required("Obbligatorio").nullable(),
      numero_tribune_fisse: yup.number().when("tribuna_fissa", {
        is: (tribuna_fissa) => tribuna_fissa === true,
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      capienza_tribuna_fissa: yup.number().when("tribuna_fissa", {
        is: (tribuna_fissa) => tribuna_fissa === true,
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      copertura_tribuna_fissa: yup.boolean().when("tribuna_fissa", {
        is: (tribuna_fissa) => tribuna_fissa === true,
        then: yup
          .boolean()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
        otherwise: yup.boolean().nullable(),
      }),
      tribuna_mobile: yup.boolean().required("Obbligatorio").nullable(),
      capienza_tribuna_mobile: yup.number().when("tribuna_mobile", {
        is: (tribuna_mobile) => tribuna_mobile === true,
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      numero_tribune_mobili: yup.number().when("tribuna_mobile", {
        is: (tribuna_mobile) => tribuna_mobile === true,
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      accessibilita_pubblico: yup.boolean().required("Obbligatorio").nullable(),
      id_tipologia_campo_singolo: yup.number().when("disciplina", {
        is: (disciplina) => disciplina == "Tennis",
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
      tipologie_campi_coperti: yup.number().when("id_tipo_campo_omologazione", {
        is: (id_tipo_campo_omologazione) => id_tipo_campo_omologazione === 2,
        then: yup
          .number()
          .required("Obbligatorio")
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
        otherwise: yup
          .number()
          .nullable()
          .transform((value) => (isNaN(value) ? undefined : value)),
      }),
    })
  ),
});
